<template>
  <section class="m-merchant-type m-scroll">
    <!-- 头部导航 -->
    <m-header title="Language"></m-header>
    <van-radio-group v-model="radio" class="type-radio">
      <van-cell-group>
        <van-cell  clickable @click="radio = 'en'" class="type-cell">
          <template #title>
            <img src="@/assets/public/en@2x.png" alt="language-en" class="language-icon">
            <span class="custom-title">English</span>
          </template>
          <template #right-icon>
            <van-radio name="en" />
          </template>
        </van-cell>
        <van-cell  clickable @click="radio = 'zh'" class="type-cell">
          <template #title>
            <img src="@/assets/public/zh@2x.png" alt="language-zh" class="language-icon">
            <span class="custom-title">中文（简体）</span>
          </template>
          <template #right-icon>
            <van-radio name="zh" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <div class="footer">
      <van-button @click="changeLang" round block type="info"  color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Done</van-button>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      radio: 'en',
    }
  },
  methods:{
    changeLang() {
      // 切换语言
      if(this.radio == 'zh') {
        localStorage.setItem('lang', 'zh')
        this.$router.push('/zh')
      } else {
        this.$router.push('/en')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.m-merchant-type {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  overflow-x: hidden;
  overflow-y: auto;
  .type-radio {
    margin-top: 12px;
  }
  .type-cell {
    height: 70px;
    /deep/ .van-cell__title {
      display: flex;
      align-items: center;;
    }
  }
  .language-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  .footer {
    margin: 100px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
  }
}
 
</style>

